/* ========================================================================
 * DOM-based Routing
 * Based on https://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function () {

                function setupNotificationBar() {
                    var secondaryTopHeight = $('.container-fluid.nav-secondary').outerHeight(),
                        notificationBarHeight = $('.notification-bar').outerHeight();
                    setTimeout(function () {
                        $( '<style>.header-landing .nav-primary.fixed { top: ' + secondaryTopHeight + 'px; }.header-other .nav-secondary { top: ' + notificationBarHeight + 'px; }.header-other .nav-primary.fixed { top: ' + (notificationBarHeight + secondaryTopHeight) + 'px; }.header-other{ margin-bottom: ' + (notificationBarHeight + secondaryTopHeight) + 'px; }</style>' ).appendTo( 'head' );
                    }, 1500);
                }


                if ($('body').hasClass('notification-bar-enabled')) {
                    $(window).resize(function () {
                        setupNotificationBar();
                    });
                    setupNotificationBar();
                }

                function valuelineAuth(e) {
                    e.preventDefault();
                    var cardNum = $('.valueline-card').val();
                    var firstFive = cardNum.substring(0, 5);
                    if (cardNum.length === 14 && (firstFive === '21117' || firstFive === '22021') && $.isNumeric(cardNum)) {
                        console.log('Authenticated. Redirecting...');
                        $('.valueline-error p, .valueline-submit').css('display', 'none');
                        $('.submit-loader-wrapper').css('display', 'inline-block');
                        // SUBMUT FORM
                        $('.valueline-form').submit();
                    } else {
                        $('.valueline-error p').slideDown();
                    }
                }

                function removeClick() {
                    setTimeout(function () {
                        $('button.secondary, .button.secondary, .eventOne, .eventTwo, .button.neutral').removeClass('clicked');
                    }, 1200);
                }

                $('.valueline-submit').click(function (e) {
                    valuelineAuth(e);
                });

                $('#ninja_forms_form_12').submit(function () {
                    ga('send', 'event', 'Form', 'Send', 'Ask a Librarian');
                });

                $('button.secondary, .button.secondary, .eventOne, .eventTwo, .button.neutral').each(function () {
                    $(this).append('<span class="button-arrow"><img src="https://www.greenwichlibrary.org/wp-content/themes/greenwich-sage/assets/images/icons/button-arrow.svg"></span>');
                });

                $('button.secondary, .button.secondary, .eventOne, .eventTwo, .button.neutral').click(function () {
                    $(this).addClass('clicked');
                    removeClick();
                });


                function childrenYak() {
                    $('body.page-id-2897').find('.vc_row:nth-child(5)').find('.wpb_column:nth-child(2)').addClass('yak-module');
                    yakNum = $('.yak-id').attr('id');
                    switch (yakNum) {
                        case "1":
                            yakUrl = "https://greenwichlib.wpengine.com/wp-content/uploads/2016/04/where-the-wild-yaks-are.svg";
                            break;
                        case "2":
                            yakUrl = "https://greenwichlib.wpengine.com/wp-content/uploads/2016/04/reading-yak.svg";
                            break;
                        case "3":
                            yakUrl = "https://greenwichlib.wpengine.com/wp-content/uploads/2016/04/bowler-hat-yak.svg";
                            break;
                        case "4":
                            yakUrl = "https://greenwichlib.wpengine.com/wp-content/uploads/2016/04/reading-glasses-yak.svg";
                            break;
                        case "5":
                            yakUrl = "https://greenwichlib.wpengine.com/wp-content/uploads/2016/04/july-fourth-yak.svg";
                            break;
                        case "6":
                            yakUrl = "https://greenwichlib.wpengine.com/wp-content/uploads/2016/04/christmas-yak.svg";
                            break;
                        case "7":
                            yakUrl = "https://greenwichlib.wpengine.com/wp-content/uploads/2016/04/yak-in-the-hat.svg";
                            break;
                        case "8":
                            yakUrl = "https://greenwichlib.wpengine.com/wp-content/uploads/2016/04/bow-yak.svg";
                            break;
                        case "9":
                            yakUrl = "https://greenwichlib.wpengine.com/wp-content/uploads/2016/04/halloween-yak.svg";
                            break;
                        default:
                            yakUrl = "https://greenwichlib.wpengine.com/wp-content/uploads/2016/04/where-the-wild-yaks-are.svg";
                    }
                    $('.yak-module').append('<a href="/yak"><img src="' + yakUrl + '" class="yak-image" alt="Greenwich Library Yak"/></a>');
                    $('.yak-image').hover(
                        function () {
                            $(this).addClass('left-one').clearQueue();
                            $(this).delay(140).queue(function () {
                                $(this).addClass('right').clearQueue();
                                $(this).delay(140).queue(function () {
                                    $(this).addClass('final').clearQueue();
                                });
                            });
                        },
                        function () {
                            $(this).removeClass('left-one right final').clearQueue();
                        }
                    );
                }


                function subnavParallax() {
                    var bodyScrollTop = $(window).scrollTop();
                    $('.subnav-hero .message-block').css('top', 'calc(50% - ' + (bodyScrollTop / 2.5) + 'px)');
                    if ($('.standard-subnav-wrapper').hasClass('header-image')) {
                        $('.subnav-hero').css('top', (bodyScrollTop / 2.5));
                    }
                }

                function landingParallax() {
                    var bodyScrollTop = $(window).scrollTop();
                    $('.landing-hero-image').css('top', (bodyScrollTop / 2.5));
                }

                function internalParallax() {
                    var bodyScrollTop = $(window).scrollTop();
                    $('.internal-hero').css('top', (bodyScrollTop / 2.5));
                }

                function toggleMobileMenu() {
                    $('.mobile-menu-container').toggleClass('mobile-submenu-open');
                    $('#mobile-submenu-container').toggleClass('open');
                }

                function removeCloseBackground() {
                    $('#mobile-submenu-container').removeClass('help learn about visit explore donate-page reimagine');
                }

                function closeMobileMenu() {
                    $('.mobile-menu').slideUp(300);
                    setTimeout(function () {
                        removeCloseBackground();
                        $('.options').removeClass('hide-icons');
                        $('.close-submenu-wrapper').removeClass('open');
                        $('#mobile-submenu-container').removeClass('open');
                        $('.mobile-menu-container').removeClass('mobile-submenu-open');
                        $('.mobile-menu-container ul li').removeClass('slide-up');
                        $('.mobile-menu-container ul li h3').removeClass('align-left');
                        $('body').removeClass('mobile-menu-toggle');
                        $('.mobile-menu').removeClass('shown');
                        $('.submenu-list-holder').each(function () {
                            $(this).find('.mobile-submenu').removeClass('slide-left');
                        });
                    }, 300);
                }

                function toggleSearch() {
                    $('.search-box').toggleClass('shown');
                    setTimeout(function () {
                        $('.text-submit').toggleClass('shown');
                    }, 700);
                    setTimeout(function () {
                        $('.radio-buttons').toggleClass('slide-in');
                    }, 1000);
                }

                function scrollMenuAnimation() {
                    var bodyScrollTop = $(window).scrollTop();
                    var minusOffset = 84
                    if ($('body').hasClass('notification-bar-enabled')) {
                        minusOffset = $('.container-fluid.nav-secondary').outerHeight();
                    }
                    var imageHeight = ($('.landing-hero').height() - minusOffset);
                    var scrollToMenu = (imageHeight);
                    if (bodyScrollTop <= imageHeight) {
                        $("html, body").animate({
                            scrollTop: scrollToMenu
                        });
                    }
                }

                function landingMenuFixed() {
                    var bodyScrollTop = $(window).scrollTop();
                    var primaryTop = $('.nav-primary').offset().top;
                    var minusOffset = 84
                    if ($('body').hasClass('notification-bar-enabled')) {
                        minusOffset = $('.container-fluid.nav-secondary').outerHeight();
                    }
                    var imageHeight = ($('.landing-hero').height() - minusOffset);
                    var scrollToMenu = (imageHeight - 150);

                    function collapseGradient() {
                        if ((primaryTop - bodyScrollTop) <= 281) {
                            var maxHeight = (primaryTop - bodyScrollTop);
                            $('.gradient-box').css('height', maxHeight);
                        }
                    }

                    if ($(window).width() > 1199) {
                        collapseGradient();
                        if (bodyScrollTop >= imageHeight) {
                            $('.nav-primary, .landing-hero, .nav-secondary, .gradient-box').addClass('fixed');
                        } else {
                            $('.nav-primary, .landing-hero, .nav-secondary, .gradient-box').removeClass('fixed');
                        }
                    } else if (($(window).width() <= 1199) && ($(document).width() > 991)) {
                        collapseGradient();
                        if (bodyScrollTop >= imageHeight) {
                            $('.nav-primary, .landing-hero, .nav-secondary, .gradient-box').addClass('fixed');
                        } else {
                            $('.nav-primary, .landing-hero, .nav-secondary, .gradient-box').removeClass('fixed');
                        }
                    } else if (($(window).width() <= 991) && ($(document).width() > 767)) {
                        if (bodyScrollTop >= 307) {
                            var maxHeight = 190 - (bodyScrollTop - 307);
                            $('.gradient-box').css('height', maxHeight);
                        }
                        if (bodyScrollTop >= 413) {
                            $('.nav-primary, .landing-hero, .nav-secondary, .gradient-box').addClass('fixed');
                        } else {
                            $('.nav-primary, .landing-hero, .nav-secondary, .gradient-box').removeClass('fixed');
                        }
                    } else if ($(window).width() < 767) {
                        if (bodyScrollTop >= 352) {
                            var maxHeight = 190 - (bodyScrollTop - 352);
                            $('.gradient-box').css('height', maxHeight);
                        }
                        if (bodyScrollTop >= 458) {
                            $('.nav-primary, .landing-hero, .nav-secondary, .gradient-box').addClass('fixed');
                        } else {
                            $('.nav-primary, .landing-hero, .nav-secondary, .gradient-box').removeClass('fixed');
                        }
                    }
                }

                function subnavMenuFixed() {
                    var bodyScrollTop = $(window).scrollTop();

                    function setFixed(subtractImage) {
                        $('.standard-subnav-menu ul').addClass('fixed');
                        if (bodyScrollTop >= ($('.standard-subnav-content').height() + subtractImage - $('.standard-subnav-menu ul').height()) - 72) {
                            $('.standard-subnav-menu ul').addClass('footer-fixed');
                            var offsetHeight = ($('.standard-subnav-content').height() - $('.standard-subnav-menu ul').height() - 72);
                            $('.standard-subnav-menu ul').css('top', offsetHeight);
                        } else {
                            $('.standard-subnav-menu ul').removeClass('footer-fixed');
                            $('.standard-subnav-menu ul').css('top', '180px');
                        }
                    }

                    function setSecondaryFixed() {
                        $('.standard-subnav-menu ul').addClass('fixed');
                        if (bodyScrollTop >= ($('.standard-subnav-content').height() - $('.standard-subnav-menu ul').height() - 22)) {
                            $('.standard-subnav-menu ul').addClass('footer-fixed');
                            var offsetHeight = ($('.standard-subnav-content').height() - $('.standard-subnav-menu ul').height() - 72);
                            $('.standard-subnav-menu ul').css('top', offsetHeight);
                        } else {
                            $('.standard-subnav-menu ul').removeClass('footer-fixed');
                            $('.standard-subnav-menu ul').css('top', '180px');
                        }
                    }

                    function unsetFixed() {
                        $('.standard-subnav-menu ul').removeClass('fixed');
                        $('.standard-subnav-menu ul').css('top', 0);
                    }

                    if ($('.standard-subnav-wrapper').hasClass('header-image')) {
                        if ($(window).width() > 1199) {
                            if (bodyScrollTop >= 562) {
                                var subtractImage = 562;
                                setFixed(subtractImage);
                            } else {
                                unsetFixed();
                            }
                        } else if (($(window).width() <= 1199) && ($(document).width() > 991)) {
                            if (bodyScrollTop >= 496) {
                                var subtractImage = 496;
                                setFixed(subtractImage);
                            } else {
                                unsetFixed();
                            }
                        }
                    } else if ($('.standard-subnav-wrapper').hasClass('no-header-image')) {
                        if (bodyScrollTop >= 50) {
                            setSecondaryFixed();
                        } else {
                            unsetFixed();
                        }
                    }
                }

                function scrollToAnchor(href) {
                    var scrllDistance = $(href).offset().top;
                    $('html, body').animate({
                        scrollTop: scrllDistance
                    }, 'slow');
                }

                function mobileSubnavMenu() {
                    var mobileSelectedSubnav = $('.mobile-subnav-page-menu').find('li:first-child a').html();
                    $('.selected-subnav-item').html(mobileSelectedSubnav);
                    $('.mobile-subnav-page-menu div ul li a').click(function () {
                        $('body').removeClass('mobile-subnav-menu-toggle');
                        var newSelection = $(this).html();
                        $('.selected-subnav-item').html(newSelection);
                        $('.selected-subnav-item').toggleClass('open');
                        $('.mobile-subnav-page-menu').slideToggle().toggleClass('open');
                    });
                    $('.menu-display').click(function () {
                        if ($(window).width() < 768 && $(window).scrollTop() >= 264 && !($('body').hasClass('mobile-subnav-menu-toggle')) && !($('.selected-subnav-item').hasClass('open'))) {
                            setTimeout(function () {
                                $('body').addClass('mobile-subnav-menu-toggle');
                            }, 300);
                        } else if ($(window).width() < 992 && $(window).scrollTop() >= 277 && !($('body').hasClass('mobile-subnav-menu-toggle')) && !($('.selected-subnav-item').hasClass('open'))) {} else if ($('body').hasClass('mobile-subnav-menu-toggle')) {
                            $('body').removeClass('mobile-subnav-menu-toggle');
                        }
                        setTimeout(function () {
                            $('.selected-subnav-item').toggleClass('open');
                            $('.mobile-subnav-page-menu').slideToggle().toggleClass('open');
                        }, 300);
                    });
                    $(document).scroll(function () {
                        if ($(window).width() < 768 && $(window).scrollTop() >= 264 && !($('body').hasClass('mobile-subnav-menu-toggle')) && ($('.selected-subnav-item').hasClass('open'))) {
                            $('body').addClass('mobile-subnav-menu-toggle');
                        } else if ($(window).width() < 992 && $(window).scrollTop() >= 277 && !($('body').hasClass('mobile-subnav-menu-toggle')) && ($('.selected-subnav-item').hasClass('open'))) {

                        } else if ($('body').hasClass('mobile-subnav-menu-toggle')) {
                            $('body').removeClass('mobile-subnav-menu-toggle');
                        }
                    });
                }

                function mobileSubnavMenuStick() {
                    if ($('.standard-subnav-wrapper').hasClass('header-image')) {
                        var bodyScrollTop = $(window).scrollTop();
                        if (($(window).width() < 992) && ($(window).width() > 767)) {
                            if (bodyScrollTop >= 277) {
                                $('.subnav-menu-wrapper, .standard-subnav-wrapper, .breadcrumb-wrapper').addClass('fixed');
                            } else {
                                $('.subnav-menu-wrapper, .standard-subnav-wrapper, .breadcrumb-wrapper').removeClass('fixed');
                            }
                        } else if ($(window).width() <= 767) {
                            if (bodyScrollTop >= 264) {
                                $('.subnav-menu-wrapper, .standard-subnav-wrapper, .breadcrumb-wrapper').addClass('fixed');
                            } else {
                                $('.subnav-menu-wrapper, .standard-subnav-wrapper, .breadcrumb-wrapper').removeClass('fixed');
                            }
                        }
                    } else if ($('.standard-subnav-wrapper').hasClass('no-header-image')) {
                        if ($(window).scrollTop() >= 0) {
                            $('.subnav-menu-wrapper, .standard-subnav-wrapper, .breadcrumb-wrapper').addClass('fixed');
                        } else {
                            $('.subnav-menu-wrapper, .standard-subnav-wrapper, .breadcrumb-wrapper').removeClass('fixed');
                        }
                    }
                }

                // ****************** //
                //   MODULE SCRIPTS   //
                // ****************** //

                // ADD CLASS TO FEATURE MODULE DEPENDING ON WIDTH
                $('.feature-img-wrapper').each(function () {
                    var column_class = $(this).closest('.vc_column_container').attr('class');
                    if (/vc_col-sm-3/i.test(column_class)) {
                        $(this).addClass('three-col');
                    } else if (/vc_col-sm-4/i.test(column_class)) {
                        $(this).addClass('four-col');
                    } else if (/vc_col-sm-6/i.test(column_class)) {
                        $(this).addClass('six-col');
                    } else if (/vc_col-sm-8/i.test(column_class)) {
                        $(this).addClass('eight-col');
                    } else if (/vc_col-sm-9/i.test(column_class)) {
                        $(this).addClass('nine-col');
                    } else if (/vc_col-sm-12/i.test(column_class)) {
                        $(this).addClass('twelve-col');
                    }
                });
                $('.feature-card').each(function () {
                    var color = $(this).data('color');
                    var headlineText = $(this).find('.feature-text h1, .feature-text h2, .feature-text h3');
                    var oldHeadline = headlineText.html();
                    headlineText.html('<span data-color=' + color + '>' + oldHeadline + '</span>');
                    color = '';
                    headlineText = '';
                    oldHeadline = '';
                });

                // SET UP TEXT MODULES
                $('.module').each(function () {
                    var color = $('body').attr('id');
                    // headlineText = $(this).find('h1, h2, h3');
                    // oldHeadline = headlineText.html();
                    // headlineText.html('<span data-color=' + color + '>' + oldHeadline + '</span>');
                    var textcolor = '';
                    switch (color) {
                        case 'home-main':
                            textcolor = '#019875';
                            break;
                        case 'about':
                            textcolor = '#4DB79F';
                            break;
                        case 'explore':
                            textcolor = '#EB9532';
                            break;
                        case 'donate-page':
                            textcolor = '#446CB3';
                            break;
                        case 'help':
                            textcolor = '#D91E18';
                            break;
                        case 'visit':
                            textcolor = '#D2527F';
                            break;
                        case 'learn':
                            textcolor = '#1DB9FF';
                            break;
                        case 'reimagine':
                          textcolor = '#009a4e';
                          break;
                        default:
                            textcolor = '#019875';
                    }
                    $(this).find('a').css('color', textcolor);
                    color = '';
                    textcolor = '';
                    var headlineText = '';
                    var oldHeadline = '';

                    if ($(this).hasClass('wpb_column')) {
                        $(this).css('padding', 0);
                        $(this).find('.wpb_single_image').css('margin-bottom', 0);
                        $(this).find('.wpb_text_column').css({
                            'margin-bottom': 0,
                            'padding': '0 35px 42px'
                        });
                        $(this).closest('.vc_row').css({
                            'padding-left': '15px',
                            'padding-right': '15px'
                        });
                    } else if ($(this).hasClass('wpb_single_image') || $(this).hasClass('wpb_video_widget')) {
                        $(this).css('padding', 0);
                    }
                });

                $('h1, h2, h3').each(function () {
                    if (!$(this).parent().hasClass('feature-text') && !$(this).parent().hasClass('message-block') && !$(this).parent().hasClass('cta-block')) {
                        var color = $('body').attr('id');
                        if ($('body').hasClass('category')) {
                            color = 'explore';
                            $('body').attr('id', '');
                            $('body').attr('id', 'explore');
                        }
                        var oldHeadline = $(this).html();
                        $(this).html('<span data-color=' + color + '>' + oldHeadline + '</span>');
                    }
                });

                // ADD CLASS TO AUTHOR QUOTE DEPENDING ON PARENT CATEGORY
                $('.author-quote').each(function () {
                    var body_id = $(this).closest('body').attr('id');
                    $(this).addClass(body_id);
                });

                var authorHeight = $('.author-quote').find('.col-sm-3').height();
                var quoteHeight = $('.author-quote').find('.col-sm-9').height();

                if (authorHeight > quoteHeight) {
                    $('.author-quote').find('.col-sm-9').css('height', authorHeight);
                    $('.author-quote').find('.col-sm-9 h3').addClass('vertical-center');
                } else if (authorHeight < quoteHeight) {
                    $('.author-quote').find('.col-sm-3').css('height', quoteHeight);
                    $('.author-quote').find('.col-sm-3 p').addClass('vertical-center');
                }
                $(window).resize(function () {
                    if (authorHeight > quoteHeight) {
                        $('.author-quote').find('.col-sm-9').css('height', authorHeight);
                        $('.author-quote').find('.col-sm-9 h3').addClass('vertical-center');
                    } else if (authorHeight < quoteHeight) {
                        $('.author-quote').find('.col-sm-3').css('height', quoteHeight).addClass('vertical-center');
                        $('.author-quote').find('.col-sm-3 p').addClass('vertical-center');
                    }
                });

                // *** EVANCED INTEGRATION *** //

                // DATEPICKER AND EVENTS
                var date = new Date();
                var year = date.getFullYear();
                var month = (1 + date.getMonth());
                month = String(month).length > 1 ? month : '0' + month;
                var day = date.getDate();
                day = String(day).length > 1 ? day : '0' + day;
                var evancedDate = String(year + '-' + month + '-' + day);
                var currentUrl = window.location.href;
                var pastEventArray = [];
                var activeEventArray = [];
              $('.datepicker-wrapper').addClass('loading-events');

                function populateEvents(activeEventArray) {

                    function eventPopulate() {

                        var parentContainer = $('.datepicker-wrapper #events-container');

                        parentContainer.find('.event-one.event-container').not('#event-container-repeat').remove();

                        for (var i = 0; i < activeEventArray.length; i++) {

                            if (i == 5) {
                                break;
                            }

                            var humanDate = new Date(activeEventArray[i].start.split("T")[0]);
                            humanDate = humanDate.toUTCString();
                            humanDate = humanDate.split(" ");
                            humanDate[1] = humanDate[1].split('');
                            if (humanDate[1][0] === "0") {
                                humanDate[1] = humanDate[1][1];
                            } else {
                                humanDate[1] = humanDate[1].join('');
                            }
                            humanDate = String(humanDate[2] + " " + humanDate[1]);

                            var time = (activeEventArray[i].start.split("T")[1]).split(':');
                            var hours = Number(time[0]);
                            var minutes = Number(time[1]);

                            if (activeEventArray[i].allday === true) {

                                var timeValue = 'All Day';

                            } else {

                                var timeValue = "" + ((hours > 12) ? hours - 12 : hours);
                                timeValue += (minutes < 10) ? ":0" + minutes : ":" + minutes;
                                timeValue += (hours >= 12) ? " pm" : " am";

                            }

                            var eventContainer = $('.datepicker-wrapper #event-container-repeat').clone();
                            //console.log(eventContainer.children('.event-title').text());

                            eventContainer.css('display', 'block');
                            eventContainer.removeAttr('id');
                            eventContainer.find('.event-title').html(activeEventArray[i].title);
                            eventContainer.find('.event-time').html(timeValue);
                            eventContainer.find('.event-date').html(humanDate);

                            // if (activeEventArray[i].LocationName === 'Cos Cob Library') {
                            //     eventContainer.find('.event-location').html('Cos Cob');
                            // } else if (activeEventArray[i].LocationName === 'Greenwich Library') {
                            //     eventContainer.find('.event-location').html('Main Branch');
                            // } else if (activeEventArray[i].LocationName === 'Byram Shubert Library') {
                            //     eventContainer.find('.event-location').html('Byram Shubert');
                            // } else {
                            //     eventContainer.find('.event-location').html('All Libraries');
                            // }

                            if (activeEventArray[i].location.name) {
                                eventContainer.find('.event-location').html(activeEventArray[i].location.name);
                            } else {
                                eventContainer.find('.event-location').html('All Libraries');
                            }
                            eventContainer.find('.button.eventOne').attr('href', activeEventArray[i].url.public);

                            eventContainer.insertBefore(parentContainer.find('div.see-all-link'))
                                //parentContainer.append(eventContainer);
                        }

                    }

                    function placeHolderCard() {

                        var parentContainer = $('.datepicker-wrapper #events-container');
                        var eventContainer = $('.datepicker-wrapper #event-container-repeat').clone();

                        parentContainer.find('.event-one.event-container').not('#event-container-repeat').remove();

                        eventContainer.css('display', 'block');
                        eventContainer.removeAttr('id').addClass('placeholder');
                        eventContainer.find('.event-title').html('View All Upcoming Events');
                        var viewAllUrl = (/children/.test(currentUrl)) ? 'https://greenwichlibrary.libcal.com/calendar/events?cid=-1&t=m&d=0000-00-00&cal=-1&audience=3142,3016,3141,3018&inc=0' : 'https://greenwichlibrary.libcal.com/calendar/events?cid=-1&t=m&d=0000-00-00&cal=-1&inc=0';
                        eventContainer.find('.button.eventOne').attr('href', viewAllUrl);
                        parentContainer.append(eventContainer);
                    }

                    if (activeEventArray.length > 0) {
                        eventPopulate();
                        //console.log('count of events: ' + activeEventArray.length);
                        $('.see-all-link').show();
                        if ($(window).width() > 767) {
                            $('#datepicker').css('margin-bottom', '0');
                        }
                    } else if (activeEventArray.length === 0) {
                        placeHolderCard();
                        $('.see-all-link').hide();
                        if ($(window).width() > 767) {
                            $('#datepicker').css('margin-bottom', '43px');
                        }
                    }
                }

                // function eventsByPage(data) {
                    // for (i = 0; i < data.length; i++) {
                    //     eventTime = data[i].EventStart.split("T").join(" ");
                    //     if (Date.parse(date) > Date.parse(eventTime)) {
                    //         pastEventArray.push(data[i]);
                    //     } else {
                    //         if (/children/.test(currentUrl)) {
                    //             var eventString = data[i].EventTypesString.toLowerCase();
                    //             if ((eventString.indexOf('children') >= 0)) {
                    //                 activeEventArray.push(data[i]);
                    //             }
                    //         } else if (/tech-training-center/.test(currentUrl)) {
                    //             if ((data[i].EventTypes[0] === 42) || (data[i].EventTypes[1] === 42) || (data[i].EventTypes[2] === 42)) {
                    //                 activeEventArray.push(data[i]);
                    //             }
                    //         } else {
                    //             activeEventArray.push(data[i]);
                    //         }
                    //     }
                    // }
                    // populateEvents(activeEventArray);
                    // pastEventArray = [];
                    // activeEventArray = [];
                // }

                function getLibCalEvents( date ) {
                  $('.datepicker-wrapper').addClass('loading-events');
                  if (!date) {
                    date = $.datepicker.formatDate('yy-mm-dd', new Date());
                  }
                  $.ajax({
                    url: sageConfig.ajax_url,
                    dataType: 'json',
                    data: {
                      'action': 'libcal',
                      'nonce': sageConfig.libcal_nonce,
                      'date': date,
                      'children': /children/.test(currentUrl)
                    },
                    complete: function(data, textStatus, xhr) {
                      var response = $.parseJSON(data.responseText);
                      if (response.success === true) {
                        populateEvents(response.events);
                      } else {
                        console.log(response.message);
                      }
                      $('.datepicker-wrapper').removeClass('loading-events');
                    }
                  });
                }

                getLibCalEvents();

                // Initialize datepicker
                $('#datepicker').datepicker({
                    dateFormat: "yy-mm-dd",
                    nextText: "",
                    prevText: ""
                });

                // Add link to see all
                var viewAllUrl = (/children/.test(currentUrl)) ? 'https://greenwichlibrary.libcal.com/calendar/events?cid=-1&t=m&d=0000-00-00&cal=-1&audience=3142,3016,3141,3018&inc=0' : 'https://greenwichlibrary.libcal.com/calendar/events?cid=-1&t=m&d=0000-00-00&cal=-1&inc=0';
                $('#datepicker').append('<a href="' + viewAllUrl + '" class="see-all-datepicker" target="_blank">See all</a>');
                $('#datepicker').append('<p class="datepicker-after-text">Select Date to View Events</p>');

                // On date change, populate new events
                $('#datepicker').on('change', function () {
                    var selectedDate = $(this).val();
                    getLibCalEvents(selectedDate);
                });

                // *** END EVANCED INTEGRATION *** //

                // GOOGLE MAPS OVERLAY
                $('.wpb_gmaps_widget').each(function () {
                    if ($(this).hasClass('greenwich-library')) {
                        $(this).find('.wpb_map_wraper').append('<a href="https://www.google.com/maps/place/Greenwich+Library/@41.02944,-73.6316177,17z/data=!3m1!4b1!4m2!3m1!1s0x89c29848118b6613:0xaff03168a7de3b62" target="_blank" class="map-overlay"></a>');
                    } else if ($(this).hasClass('byram-shubert')) {
                        $(this).find('.wpb_map_wraper').append('<a href="https://www.google.com/maps/place/Byram-Schubert+Library/@41.001766,-73.6553908,15z/data=!4m2!3m1!1s0x0:0xc8c757d44a28dc6" target="_blank" class="map-overlay"></a>');
                    } else if ($(this).hasClass('cos-cob')) {
                        $(this).find('.wpb_map_wraper').append('<a href="https://www.google.com/maps/place/Cos+Cob+Library/@41.0391645,-73.5994504,15z/data=!4m2!3m1!1s0x0:0xea264a5ddc9b6459" target="_blank" class="map-overlay"></a>');
                    } else if ($(this).hasClass('perrot-library')) {
                        $(this).find('.wpb_map_wraper').append('<a href="https://www.google.com/maps/place/Perrot+Memorial+Library/@41.0385563,-73.5698579,15z/data=!4m2!3m1!1s0x0:0x64b1957cc2a74bc4?sa=X&ved=0ahUKEwjev9Pd49fLAhWDSiYKHesTAZEQ_BIIfDAR" target="_blank" class="map-overlay"></a>');
                    } else if ($(this).hasClass('flinn-gallery')) {
                        $(this).find('.wpb_map_wraper').append('<a href="https://www.google.com/maps/place/The+Flinn+Gallery/@41.0287632,-73.6294717,15z/data=!4m2!3m1!1s0x0:0x3223c5b122426ac0" target="_blank" class="map-overlay"></a>');
                    } else {
                        $(this).find('.wpb_map_wraper').append('<a href="https://www.google.com/maps/place/Greenwich+Library/@41.02944,-73.6316177,17z/data=!3m1!4b1!4m2!3m1!1s0x89c29848118b6613:0xaff03168a7de3b62" target="_blank" class="map-overlay"></a>');
                    }
                });

                var featureImageLink = $('body').attr('id');
                switch (featureImageLink) {
                    case 'home-main':
                        textcolor = '#019875';
                        break;
                    case 'about':
                        textcolor = '#4DB79F';
                        break;
                    case 'explore':
                        textcolor = '#EB9532';
                        break;
                    case 'donate-page':
                        textcolor = '#446CB3';
                        break;
                    case 'help':
                        textcolor = '#D91E18';
                        break;
                    case 'visit':
                        textcolor = '#D2527F';
                        break;
                    case 'learn':
                        textcolor = '#1DB9FF';
                        break;
                    case 'reimagine':
                          textcolor = '#009a4e';
                          break;
                    default:
                        textcolor = '#019875';
                }
                $('.feature-image-message a').css('color', textcolor);

                // ****************** //
                // END MODULE SCRIPTS //
                // ****************** //

                // TOGGLE SEARCH
                $('.search-toggle').click(function () {
                    toggleSearch();
                });

                $('.search-again').click(function () {
                    toggleSearch();
                });

                $('.search-from-menu').click(function () {
                    toggleMobileMenu();
                    removeCloseBackground();
                    closeMobileMenu();
                    setTimeout(function () {
                        toggleSearch();
                    }, 300);
                });

                $('.close-search').click(function () {
                    $('.search-box').removeClass('shown');
                    setTimeout(function () {
                        $('.text-submit').toggleClass('shown');
                    }, 700);
                    setTimeout(function () {
                        $('.radio-buttons').toggleClass('slide-in');
                    }, 1000);
                });

                // SUBMENU ANIMATION SLIDE -- DESKTOP
                $('.submenu-toggle').click(function () {
                    if ($(this).closest('li').hasClass('active')) {
                        $('.menu-feature-wrapper').slideUp('fast');
                        $(this).closest('li').removeClass('active');
                        setTimeout(function () {
                            $('header').removeClass('menu-open');
                        }, 200);
                    } else {
                        $('header').addClass('menu-open');
                        $('.menu-feature-wrapper').slideUp('fast');
                        $('.primary-nav-items li').removeClass('active');
                        toggleClass = $(this).closest('li').data('menu');
                        toggleMenu = $('.submenu-wrapper').find('.' + toggleClass).closest('.menu-feature-wrapper');
                        toggleMenu.delay(200).slideDown(500);
                        $(this).closest('li').addClass('active');
                    }
                });
                $('html').click(function () {
                    // On click outside of the nav-primary area
                    $('.menu-feature-wrapper').slideUp('fast');
                    $('.primary-nav-items li').removeClass('active');
                });
                $('.nav-primary').click(function (event) {
                    // Prevents clicking inside the nav-primary area from bubbling up the DOM and triggering the above close-menu actions
                    event.stopPropagation();
                });

                // SUBMENU ANIMATION SLIDE -- MOBILE/TABLET
                $('.secondary-nav-icons .menu').click(function () {
                    $('body').addClass('mobile-menu-toggle');
                    $('.mobile-menu').addClass('shown').slideDown(300);
                    var liNum = $('.mobile-menu-container>ul li').length;
                    setTimeout(function () {
                        $('.mobile-menu-container>ul li:nth-child(1)').addClass('slide-up');
                    }, 300);
                    setTimeout(function () {
                        $('.mobile-menu-container>ul li:nth-child(2)').addClass('slide-up');
                    }, 450);
                    setTimeout(function () {
                        $('.mobile-menu-container>ul li:nth-child(3)').addClass('slide-up');
                    }, 600);
                    setTimeout(function () {
                        $('.mobile-menu-container>ul li:nth-child(4)').addClass('slide-up');
                    }, 750);
                    setTimeout(function () {
                        $('.mobile-menu-container>ul li:nth-child(5)').addClass('slide-up');
                    }, 900);
                    setTimeout(function () {
                        $('.mobile-menu-container>ul li:nth-child(6)').addClass('slide-up');
                    }, 1050);
                    setTimeout(function () {
                        $('.mobile-menu-container>ul li:nth-child(7)').addClass('slide-up');
                    }, 1200);
                });

                $('.mobile-menu-container ul li').click(function () {
                    itemClicked = $(this);
                    $('#mobile-submenu-container').scrollTop(0);
                    // If a menu is already open
                    if ($('.mobile-menu-container').hasClass('mobile-submenu-open')) {
                        // And the same menu item is selected again
                        if (itemClicked.hasClass('active-mobile-item')) {
                            // Toggle active menu closed
                            toggleMobileMenu();
                            // Remove existing active attributes
                            setTimeout(function () {
                                $('.options').toggleClass('hide-icons');
                                $('.close-submenu-wrapper').toggleClass('open');
                                removeCloseBackground();
                                $('.mobile-menu-container ul li').each(function () {
                                    $(this).removeClass('active-mobile-item');
                                });
                                $('.submenu-list-holder').each(function () {
                                    $(this).removeClass('shown');
                                    $(this).find('.mobile-submenu').removeClass('slide-left');
                                });
                            }, 300);
                            $('.mobile-menu-container ul li h3').each(function () {
                                $(this).toggleClass('align-left');
                            });
                        } else {
                            // Remove active attributes
                            $('.mobile-menu-container ul li').each(function () {
                                $(this).removeClass('active-mobile-item');
                            });
                            // Toggle after atribute for color bar
                            $(this).toggleClass('active-mobile-item');
                            // Select menu to show
                            selectMenu = $(this).data('mobile-menu');
                            setTimeout(function () {
                                removeCloseBackground();
                                $('#mobile-submenu-container').toggleClass(selectMenu);
                            }, 300);
                            selectedMenu = $('#mobile-submenu-container').find('*[data-toggle-menu=' + selectMenu + ']');

                            // Toggle current active menu closed
                            toggleMobileMenu();

                            // Toggle new active menu open
                            setTimeout(function () {
                                // Remove active fields from all other menus
                                $('.submenu-list-holder').each(function () {
                                    $(this).removeClass('shown');
                                    $(this).find('.mobile-submenu').removeClass('slide-left');
                                });
                                // Toggle shown status on correct menu
                                selectedMenu.toggleClass('shown');
                                setTimeout(function () {
                                    selectedMenu.find('.mobile-submenu').addClass('slide-left');
                                }, 150);
                                toggleMobileMenu();
                            }, 300);
                        }
                    } else {
                        $('.mobile-menu-container ul li h3').addClass('bounce-right');
                        setTimeout(function () {
                            $('.mobile-menu-container ul li h3').removeClass('bounce-right');
                            $('.mobile-menu-container ul li h3').toggleClass('align-left');
                        }, 300);

                        // Toggle options visibility
                        if ($('.mobile-menu-container').hasClass('mobile-submenu-open')) {
                            setTimeout(function () {
                                $('.options').toggleClass('hide-icons');
                                $('.close-submenu-wrapper').toggleClass('open');
                            }, 300);
                        } else {
                            $('.options').toggleClass('hide-icons');
                            $('.close-submenu-wrapper').toggleClass('open');
                        }
                        // Toggle color bar after active list item
                        $('.mobile-menu-container ul li').each(function () {
                            $(this).removeClass('active-mobile-item');
                        });
                        $(this).toggleClass('active-mobile-item');
                        // Select menu to show
                        selectMenu = $(this).data('mobile-menu');
                        selectedMenu = $('#mobile-submenu-container').find('*[data-toggle-menu=' + selectMenu + ']');
                        $('#mobile-submenu-container').toggleClass(selectMenu);
                        // Remove active fields from all other menus
                        $('.submenu-list-holder').each(function () {
                            $(this).removeClass('shown');
                            $(this).find('.mobile-submenu').removeClass('slide-left');
                        });
                        // Toggle shown status on correct menu
                        selectedMenu.toggleClass('shown');
                        // Toggle menu slide
                        setTimeout(function () {
                            setTimeout(function () {
                                selectedMenu.find('.mobile-submenu').addClass('slide-left');
                            }, 150);
                            toggleMobileMenu();
                        }, 300);
                    }
                });

                $('.mobile-submenu li').click(function () {
                    closeMobileMenu();
                });

                $('.close-submenu, .close-menu').click(function () {
                    closeMobileMenu();
                });

                $('.primary-nav-items ul li').click(function () {
                    scrollMenuAnimation();
                });

                $(document).ready(function () {
                    closeMobileMenu();
                    childrenYak();
                    landingMenuFixed();
                    subnavMenuFixed();
                    mobileSubnavMenu();
                    mobileSubnavMenuStick();
                    $(document).scroll(function () {
                        landingMenuFixed();
                        subnavMenuFixed();
                        mobileSubnavMenuStick();
                        landingParallax();
                        subnavParallax();
                        internalParallax();
                    });
                    $(window).resize(function () {
                        landingMenuFixed();
                        subnavMenuFixed();
                        mobileSubnavMenuStick();
                    });
                });

                $('.standard-subnav-menu div ul li a, .mobile-subnav-page-menu div ul li a').click(function (e) {
                    e.preventDefault();
                    hrefTest = $(this).attr('href');
                    if (/\#/i.test(hrefTest)) {
                        scrollToAnchor(hrefTest);
                    } else {
                        if (/greenwichlibrary\.org/i.test(hrefTest)) {
                            $(location).attr('href', hrefTest);
                        } else {
                            window.open(hrefTest);
                        }
                    }
                });

                // SEARCH FORM, BASED ON RADIO BUTTON SELECTION
                $(document).keyup(function () {
                    var queryInput = $('input.query').val();
                    var checkedVal = $('.radio-buttons input.checked');
                    console.log($(checkedVal).hasClass('site'));

                    Date.prototype.yyyymmdd = function () {
                        var yyyy = this.getFullYear().toString();
                        var mm = (this.getMonth() + 1).toString(); // getMonth() is zero-based
                        var dd = this.getDate().toString();
                        var date = yyyy + '/' + (mm[1] ? mm : "0" + mm[0]) + '/' + (dd[1] ? dd : "0" + dd[0]);
                        return date;
                    };
                    d = new Date();
                    var todaysDate = d.yyyymmdd();

                    if ($(checkedVal).hasClass('site')) {
                        $('.searchForm').attr('action', 'https://www.greenwichlibrary.org/?s=' + queryInput);
                    } else if ($(checkedVal).hasClass('evanced')) {
                        if ($('input[name=kw]').length > 0) {
                            $('input[name=kw]').attr('value', queryInput);
                        } else {
                            $('.text-submit.shown').prepend('<input type="hidden" name="df" value="list" class="evenced-fields" >').append('<input type="hidden" name="kw" value="' + queryInput + '" class="evenced-fields">').append('<input type="hidden" name="ds" value="' + todaysDate + '" class="evenced-fields">');
                        }
                    }

                });

                Date.prototype.yyyymmdd = function () {
                    var yyyy = this.getFullYear().toString();
                    var mm = (this.getMonth() + 1).toString(); // getMonth() is zero-based
                    var dd = this.getDate().toString();
                    var date = yyyy + '/' + (mm[1] ? mm : "0" + mm[0]) + '/' + (dd[1] ? dd : "0" + dd[0]);
                    return date;
                };
                d = new Date();
                var todaysDate = d.yyyymmdd();

                if (!($('body').hasClass('search-no-results'))) {
                    $('input.catalog').toggleClass('checked');
                    $('.radio-buttons input').on('click', function () {
                        $('input.catalog, input.site, input.libcal').removeClass('checked');
                        $(this).addClass('checked');
                        $('.text-submit.shown .evenced-fields').remove();
                        $('input[type="hidden"]').attr('name', '').val('');
                        $('input[type="text"]').attr('name', '');
                        queryInput = $('input.query').val();
                        if ($(this).hasClass('catalog')) {
                            $('input[type="hidden"]').attr('name', 't').val('smart');
                            $('input[type="text"]').attr('name', 'q');
                            $('.searchForm').attr('action', 'https://greenwichlibrary.bibliocommons.com/search');
                        } else if ($(this).hasClass('site')) {
                            $('input[type="hidden"]').attr('name', '');
                            $('input[type="text"]').attr('name', 's');
                            $('.searchForm').attr('action', 'https://www.greenwichlibrary.org/?s=' + queryInput);
                        } else if ($(this).hasClass('libcal')) {
                            // ?cid=-1&t=m&d=0000-00-00&cal=-1&inc=0&q=
                            $('.text-submit.shown')
                              .prepend('<input type="hidden" name="cid" value="-1" class="evenced-fields" >')
                              .prepend('<input type="hidden" name="t" value="d" class="evenced-fields" >')
                              .prepend('<input type="hidden" name="d" value="0000-00-00" class="evenced-fields" >')
                              .prepend('<input type="hidden" name="cal" value="-1" class="evenced-fields" >')
                              .prepend('<input type="hidden" name="inc" value="0" class="evenced-fields" >');
                            $('.query').attr('name', 'q');
                            $('.searchForm').attr('action', 'https://greenwichlibrary.libcal.com/calendar/events');
                        }
                    });

                } else if (($('body').hasClass('search-no-results'))) {
                    $('input.catalog').removeClass('checked');
                    $('input.libcal').removeClass('checked');
                    $('input.site').toggleClass('checked');
                    $('input[type="hidden"]').attr('name', '');
                    $('input[type="text"]').attr('name', 's');
                    queryInput = $('input.query').val();
                    $('.searchForm').attr('action', 'https://www.greenwichlibrary.org/?s=' + queryInput);
                    $('.radio-buttons input').on('click', function () {
                        $('input.catalog, input.site, input.libcal').removeClass('checked');
                        $(this).addClass('checked');
                        queryInput = $('input.query').val();
                        if ($(this).hasClass('catalog')) {
                            $('.searchForm').attr('action', 'https://greenwichlibrary.bibliocommons.com/search');
                        } else if ($(this).hasClass('site')) {
                            $('input[type="hidden"]').attr('name', '');
                            $('input[type="text"]').attr('name', 's');
                            $('.searchForm').attr('action', 'https://www.greenwichlibrary.org/?s=' + queryInput);
                        }
                    });
                }

            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        // Home page
        'home': {
            init: function () {
                // JavaScript to be fired on the home page
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function () {
                // JavaScript to be fired on the about us page
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
